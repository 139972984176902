<template>
  <div class="auth-layout container row align-content--center">
    <div class="flex xs12 ">
      <div class="d-flex justify--center">
        <div class="flex md:items-end sm:items-center text-white md:pr-4" >
          <div class="flex-mobile">
            <div class="pb-4">
              <div class="text-center pb-3 font-Moon text-apexGrey">
                <h1 class="font-semibold" style="font-size: 5vh;">NIHSS<span class="text-apexRed">+</span></h1>
                <h1 class="font-semibold" style="font-size: 7vh;" >THE FUTURE</h1>
                <h4 class="font-semibold text-apexRed" style="font-size: 3vh;" >OF STROKE CERTIFICATION</h4>
              </div>
              <div class="form">
                <p class="custom-text">Sign In</p>
                <section  v-if="this.$store.state.auth.AuthError.active">
                    <div class="bg-yellow rounded-lg py-5 px-6 mb-4 text-base text-yellow mb-3 max-w-sm" role="alert">
                       <p v-html="this.$store.state.auth.AuthError.message"></p>
                    </div>
                </section>
               
                <form class="login-form" @submit.prevent="login()">
                  <input type="email"  v-model="email"   placeholder="me@email.com"/>
                  <input type="password"  v-model="password"   placeholder="*********"/>
                  <div class="flex flex-row pb-3">
                    <div class="grow"></div>
                  </div>
                  <button  class="custom-button" :disabled="!FieldsFilled"  >Sign In</button>
                  <p class="text-gray-600" style="padding-top: 0.8rem;">or</p>
                  <!-- <section id="firebaseui-auth-container" /> -->
                  <p class="message">Don't have an account? <a target="_blank" href="https://nihss.plus/register">Create one.</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

export default {
  name: 'login',
  data() {
    return {
      checked: true,
      email: '',
      password: '',
      FirestoreError: {
        active: false,
        message: '',
      },
    };
  },
  props: {
    error: {
      type: String,
      default: 'Error',
    },
  },
  computed: {
    FieldsFilled() {
      if (this.email && this.password) {
        return true;
      }
    },
  },
   mounted: function() {
    const router = this.$router;// can't access `this` in the callback
    // const uiConfig = {
    //   signInSuccessUrl: '/admin/dashboard',
    //   signInOptions: [
    //     firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //   ],
    //   callbacks: {
    //     signInSuccessWithAuthResult: function(authResult, signInSuccessUrl) {
    //       // Handle the result
    //       window.location.href = '/admin/dashboard';
    //       return false;
    //     }},
    // };
    // let ui = firebaseui.auth.AuthUI.getInstance();
    // if (!ui) {
    //   ui = new firebaseui.auth.AuthUI(firebase.auth());
    // }
    // ui.start('#firebaseui-auth-container', uiConfig);
    //get param error from url
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    //get error from $store
    if (error) {
      //disconnect user
       firebase.auth().signOut().then(() => {
        //clear from url
        this.$store.dispatch('setAuthError', {
          active: true,
          message: error,
        }) .then(() => {
          router.push({name: 'login' });
        });
      }).catch((error) => {
        this.$swal({
          title: 'Oops!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      });
    }
  },
  methods: {
    firebaseLogin(){
      return firebase.auth().signInWithEmailAndPassword(this.email, this.password).then((userCredential) => {
        const user = userCredential.user;
        this.$store.dispatch('setAuthError', {
          active: false,
          message: '',
        }).then(() => {

          /**
            * @title User Agent Detection
            * @description Detects if user is on mobile or desktop
          */
          let mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
          let isMobile = false;
          for (let i = 0; i < mobileAgents.length; i++) {
            if (navigator.userAgent.indexOf(mobileAgents[i]) > 0) {
              isMobile = true;
              break;
            }
          }
          let deviceName = navigator.userAgent.split('(')[1].split(')')[0];
          if(isMobile){
            //save in firestore
            firebase.firestore().collection('users').doc(user.uid).update({
              loginLocation: "Mobile",
              loginDevice: deviceName,
            }).then(() => {
                window.location.href = '/admin/dashboard';
            });
            }else{
              //save in firestore
              firebase.firestore().collection('users').doc(user.uid).update({
                loginLocation: "Desktop",
                loginDevice: deviceName,
              }).then(() => {
                window.location.href = '/admin/dashboard';
              });
            }
         
        });
      })
      .catch((error) => {
        //for security reasons, the error from firebase is not displayed, but a generic error is shown to the user
        let message = '';
        switch (error.code) {
          case 'auth/wrong-password':
            message = 'The email or password you entered is incorrect. Please try again.'
          case 'auth/user-not-found':
            message = 'The email or password you entered is incorrect. Please try again.'
          case 'auth/internal-error':
            message = 'The email or password you entered is incorrect. Please try again.'
          default:
            message = 'The email or password you entered is incorrect. Please try again.'
        }
        this.$store.dispatch('setAuthError', {
          active: true,
          message: message,
        });
      });
    },
    login: function() {
      this.$store.dispatch('setAuthError', {
          active: true,
          message: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>',
      });
      //check if remember me is checked
      if (!this.checked) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
          // Existing and future Auth states are now persisted in the current session only. Closing the window would clear any existing state even
          return this.firebaseLogin()
        })
      }else{
        this.firebaseLogin()
      }
    },
  }
};
</script>

<style lang="scss">

  .auth-layout {
    min-height: 100vh;
    max-width: 800px;
    margin: 0 auto;

    &__card {
      width: 100%;
      max-width: 600px;
    }

    &__options {
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }
  }

  .title {
    padding-top: 50px;
  }

  .swooftwave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
  }

  .font-Moon {
    font-family: 'Moon', sans-serif;
  }

  .text-apexGrey {
    color: #484444;
  }

  .font-semibold {
    font-weight: 400 !important;
  }

  .swooftbg {
    background-image: url('/assets/home/geometric.webp');
    background-repeat: repeat;
  }

  .bg-yellow {
    background-color: #fef0cd;
  }

  .text-yellow {
    color: #b08620;
  }

  .max-w-sm {
    max-width: 24rem !important;
    padding: 0 1rem;
  }

  /* FORM */
  .form {
    position: relative;
    z-index: 1;
    border-radius: 0.9em;
    background: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    max-width: 100%;
    padding: 45px;
    text-align: center;
  }

  .form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    color: #5e5e5e;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
  }

  .text-apexRed {
    color: rgb(167, 20, 20);
  }

  .custom-button {
    border-radius: 0.5em !important;
    border: 1px solid rgb(167, 20, 20) !important;
    background-color: rgb(167, 20, 20) !important;
    width: 25vh;
    padding: 10px;
    color: #ffffff !important;
    font-size: 1.5vh;
    cursor: pointer;
  }

  .custom-button:disabled {
    border: 1px solid rgba(73, 73, 73, 0.5) !important;
    background-color: rgba(73, 73, 73, 0.5) !important;
    color: #ffffff !important;
    width: 25vh;
    padding: 10px;
    font-size: 1.5vh;
    cursor: pointer;
  }

  /** all html with tag button make rounded */
  button {
    border-radius: 0.5em !important;
  }

  button:hover {
    opacity: 0.9;
    color: #ffffff;
  }

  .form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
  }

  .form .message a {
    color: rgb(167, 20, 20);
    text-decoration: none;
  }

  .custom-text {
    display: none;
    font-size: 2em;
    text-align: left;
    padding-bottom: 25px;
    font-weight: 600;
    color: #5e5e5e;
  }

  /* create media for lower than 577x884 */
  @media screen and (max-width: 576px) {
    .title-div {
      display: none !important;
    }

    .header-mobile {
      padding-top: 4vh !important;
      padding-left: 10vw !important;
      padding-right: 10vw !important;
    }

    .custom-text {
      display: block !important;
    }
  }

</style>
